import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import facebookIcon from '../../images/facebook.png'
import instagramIcon from '../../images/instagram.png'
import linkedInIcon from '../../images/linkedin.png'
import searchIcon from '../../images/search.png'
import nepal from '../../images/nepal.svg'
import english from '../../images/english.svg'
const HeaderTop = () => {
  const [workingHours, setWorkingHours] = useState('')
  const [nepali, setNepali] = useState(false)
  useEffect(() => {
    const date = new Date()
    const dayOfTheWeek = date.getUTCDay()
    const dayArray = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    switch (dayOfTheWeek) {
      case 5:
        setWorkingHours('Friday, 10AM to 3PM')
        break
      case 6:
        setWorkingHours('The office will remain closed today.')
        break
      default:
        setWorkingHours(`${dayArray[dayOfTheWeek]}, 9:45AM to 5PM`)
        break
    }
  }, [])
  return (
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="header-top-left">
              <div className="left">
                <div className="icon-container">
                  <a
                    href="https://www.facebook.com/gurkhasfinance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebookIcon} alt="" />
                  </a>
                </div>
                <div className="icon-container">
                  <a
                    href="https://www.instagram.com/gurkhasfinance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramIcon} alt="" />
                  </a>
                </div>
                <div className="icon-container">
                  <a
                    href="https://www.linkedin.com/company/gurkhas-finance-ltd"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedInIcon} alt="" />
                  </a>
                </div>
              </div>
              <div className="right">
                <div className="text-container">
                  <p className="office-time">
                    {workingHours || 'Toll Free 1610003030405'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="header-top-right">
              <div className="text-container">
                <Link to="/notice">
                  <p>Notice</p>
                </Link>
              </div>
              <div className="text-container">
                <Link to="/interest-rates">
                  <p>Interest rates</p>
                </Link>
              </div>
              <div className="text-container">
                <Link to="/career">
                  <p>Careers</p>
                </Link>
              </div>
              <div className="text-container">
                <Link to="/downloads">
                  <p>Downloads</p>
                </Link>
              </div>
              <div className="text-container">
                <Link to="/faq">
                  <p>FAQs</p>
                </Link>
              </div>
              <div className="text-container">
                <Link to="/emi-calculator">
                  <p>EMI calculator</p>
                </Link>
              </div>
              <div className="text-container">
                <a
                  // href="http://182.93.77.36/"
                  href="https://ibank.gurkhasfinance.com.np"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>E-Banking</p>
                </a>
              </div>
              <div className="text-container">
                <a
                  href="https://demat.gurkhasfinance.com.np/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Demat</p>
                </a>
              </div>
              <div className="search-container nav-search-container">
                <div className="input-container nav-search">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
                <div className="image-container " id="search-image-container">
                  <img src={searchIcon} alt="" />
                </div>
              </div>
              <div
                className="enligh-nepal-container"
                onClick={() => setNepali(!nepali)}
                style={{ cursor: 'pointer' }}
              >
                <div className="image-container" style={{ width: '20px' }}>
                  <img src={nepali ? nepal : english} alt="" />
                </div>
                <div className="text-container">
                  <p>{nepali ? 'NP' : 'ENG'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTop
