import React from "react";
import secondaryBannerImage from "../../images/debit card.png";
import secondaryBannerImage1 from "../../images/visa_debit_card.jpg";

const DebitCard = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features of GUFL Visa Debit Card</p>
          </div>
          <ul>
            <li>
              GUFL Visa Debit Card can be used in any of the ATMs and POS machines
              displaying Visa.
            </li>
            <li>
              Can be used in ATMs for Cash withdrawal and Balance Enquiry.
            </li>
            <li>
              Can be used in POS terminals for purchase of goods & services from
              various merchants like departmental stores, hospitals, retail
              shops and so on.
            </li>
            <li>EMV compliant Chip-based card for secured transaction.</li>
          </ul>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage1} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default DebitCard;
