import React from "react";
import secondaryBannerImage from "../../images/casba.png";

const Asba = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Application Supported by Blocked Amount (ASBA) is a process for
            applying to public/rights issues submitted by investors by blocking
            the application money in their bank account while subscribing to the
            issue. C-ASBA will ensure centralized posting and verification of
            bank account numbers and DMAT account numbers ease monitoring of
            double application for issue managers and share registrars,
            cancellation of the application upon double applications and prepare
            a final report for securities allocation. Mero Share application is
            the online system developed by CDSC through which the shareholders
            can apply application for IPO/FPO/Rights issues once they are
            registered through C-ASBA. C-ASBA is also provided by Gurkhas
            Finance Limited from all over its branches inside and outside
            Kathmandu. To avail the facility, just fill up the C-ASBA
            registration form and submit to any nearest GUFL branches.{" "}
          </p>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default Asba;
