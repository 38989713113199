import React from "react";
import { useEffect } from "react";
import CardDownload from "../../Components/CardDownload";
import SecondaryBanner from "../../Components/SecondaryBanner";

const StaffTrainingDetails = () => {
  useEffect(() => {
    document.title = "Staff Training Disclosure";
    window.scrollTo(0, 0);
  }, []);

  const bannerData = {
    breadcrumb: ["Home", "Staff Training Disclosure"],
    bannerTitle: "Staff Training Disclosure",
  };

  const cardData = [
    {
      id: 3,
      title: "Staff Training Details As On Ashad End 2081",
      link: "https://gurkhasfinance.com.np/assets/StaffTraining/Training Disclosure_staff.pdf",
      year: "",
    },
    {
      id: 2,
      title: "Staff Training Details First Half FY 2080-81",
      link: "https://gurkhasfinance.com.np/assets/StaffTraining/First Half FY 2080.81.pdf",
      year: "",
    },
    {
      id: 1,
      title: "Staff Training Details First Half FY 2079-80",
      link: "https://gurkhasfinance.com.np/assets/StaffTraining/First Half FY 2078.79.pdf",
      year: "",
    },
  ];

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {cardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default StaffTrainingDetails;
