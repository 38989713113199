import gaurabBudathoki from "../../images/GAURAB-BUDATHOKI.jpg";
import dekindra from "../../images/dekindra-sir.jpg";
import karnaBahadurRai from "../../images/Karna-Bahadur-Rai-updated.jpg";
import kalpanaKhapung from "../../images/Kalpana-Khapung-Updated(3).jpg";
import narendraKumarAgrawal from "../../images/Narendra-Kumar-Agrawal-updated.jpg";
import neelamBohara from "../../images/Neelam-Bohara-updated.jpg";
import sagarSharma from "../../images/Sagar-Sharma-updated(1).jpg";
import avatar from "../../images/avatar.webp";
import santoshKumarGhimire from "../../images/santosh-kumar-ghimire.jpg";
import mukundaShrestha from "../../images/mukunda shrestha.jpg";
import deepakNeupane from "../../images/deepak-neupane.jpg";
import sujanJoshi from "../../images/sujan-joshi.jpg";
import yanSingRai from "../../images/yan-sing-rai.jpg";
import shambuRai from "../../images/shambu-rai.jpg";
import bharatThapa from "../../images/bharat-thapa.jpg";
import dineshTamang from "../../images/dinesh-tamang.jpg";
import raushan from "../../images/raushan.jpg";
import yogendra from "../../images/yogendra .jpg";
import sahana from "../../images/sahana .jpg";
import sunitaRai from "../../images/sunita-rai.jpg";
import sunena from "../../images/sunena-.jpg";
import shantiThing from "../../images/shanti-thing.jpg";
import subashRai from "../../images/subash-rai.jpg";
import gurkhaLogo from "../../images/gurkha-square-log.png";
import GyanendraIwahang1 from "../../images/GyanendraIwahang1.jpg";
import YubarajRai from "../../images/boardOfDirector/yubaraj_rai.jpg";
import pravinBhattarai from "../../images/boardOfDirector/pravin_bhattarai.jpg";
export const auditCommittee = [
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Pravin Bhattarai",
    position: "Coordinator",
    image: pravinBhattarai,
  },
  {
    name: "",
    position: "",
    image: "",
  },
  // {
  //   name: "Karna Bahadur Rai",
  //   position: "Member",
  //   image: karnaBahadurRai,
  // },
  {
    name: "Head of Internal Audit Department ",
    position: "Member Secretary",
    image: gurkhaLogo,
  },
];
export const riskManagement = [
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Yubaraj Rai",
    position: "Coordinator",
    image: YubarajRai,
  },
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Pravin Bhattarai",
    position: "Member",
    image: pravinBhattarai,
  },
  {
    name: "Head of Operation Department",
    position: "Member Secretary",
    image: gurkhaLogo,
  },
  {
    name: "Chief Risk Officer",
    position: "Member Secretary",
    image: gurkhaLogo,
  },
];
export const staffFacilities = [
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Neelam Bohora",
    position: "Coordinator",
    image: neelamBohara,
  },
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Chief Executive Officer",
    position: "Member",
    image: gurkhaLogo,
  },
  {
    name: "Chief Finance Officer",
    position: "Member",
    image: gurkhaLogo,
  },
  {
    name: "Head, Human Resource Department",
    position: "Member Secretary",
    image: gurkhaLogo,
  },
];
export const amlCftCommittee = [
  // {
  //   name: "",
  //   position: "",
  //   image: "",
  // },
  // {
  //   name: "Karna Bahadur Rai",
  //   position: "Coordinator",
  //   image: karnaBahadurRai,
  // },
  // {
  //   name: "",
  //   position: "",
  //   image: "",
  // },
  {
    name: "kalpana Khapung",
    position: "Member",
    image: kalpanaKhapung,
  },
  {
    name: "Chief Risk Officer",
    position: "Member",
    image: gurkhaLogo,
  },
  {
    name: "Head, Compliance Department",
    position: "Member Secretary",
    image: gurkhaLogo,
  },
];
export const executiveLevel = [
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "",
    position: "CEO",
    image: gurkhaLogo,
  },
  {
    name: "",
    position: "",
    image: "",
  },
  {
    name: "Mukunda Shrestha",
    position: "Acting CEO",
    image: mukundaShrestha,
  },

  {
    name: " Gyanendra Iwahang",
    position: "Senior Manager",
    image: GyanendraIwahang1,
  },
  {
    name: "Deepak Neupane",
    position: "Senior Manager",
    image: deepakNeupane,
  },
];
export const headOfDepartment = [
  {
    name: "Sujan Joshi",
    position: "Head - IT",
    image: sujanJoshi,
  },
  {
    name: "Yan Singh Rai",
    position: "Head - Account and Finance",
    image: yanSingRai,
  },
  {
    name: "Shambhu Rai",
    position: "Head - Human Resource & Admin",
    image: shambuRai,
  },
  {
    name: "Bharat Thapa Chhetri",
    position: "Head - Debt Recovery",
    image: bharatThapa,
  },
  // {
  //   name: "Dinesh Tamang",
  //   position: "Head - Credit Operation",
  //   image: dineshTamang,
  // },
  {
    name: "Raushan Kumar Singh",
    position: "Head - Legal",
    image: raushan,
  },
  // {
  //   name: "Yogendra Suwal",
  //   position: "Head - Debt Risk",
  //   image: yogendra,
  // },
  {
    name: "Sahana Tuladhar",
    position: "Head - Internal Audit",
    image: sahana,
  },
  {
    name: "",
    position: "Head - Business Develepment",
    image: gurkhaLogo,
  },
  {
    name: "Sunina Malakar",
    position: "Head - Treasury",
    image: sunena,
  },
  {
    name: "Shanti Thing",
    position: "Head - Central Operation",
    image: shantiThing,
  },
  {
    name: "Subash Rai",
    position: "Head - D.P Section",
    image: subashRai,
  },
];
