import React from "react";
import introImg from "../../images/gurkhas fulform.jpg";
export default function Overview() {
  return (
    <section className="overview">
      <div className="container">
        <div className="wrapper">
          <div className="heading">
            <p className="title">Overview</p>
            <p className="small-light-text">
              Gurkhas Finance Limited, one of the leading finance in Nepalese
              Banking Industry has observed a good share of growth through
              organic business. The finance has completed 27 years of journey
              since its inception. The Finance has 26 branches, 1 extension
              counters and 6 ATMs across the country making it one of the
              leading finance of Nepal in terms of geographical reach and
              clientele segments with customer base more than 90,000. As one of
              the leading finance, the Finance will be there where growth is,
              connecting customers to the opportunities, enabling business to
              thrive and economies to prosper and ultimately helping people to
              fulfill their hopes and realize their ambitions. The Finance
              offers full range of banking facilities & services for personal,
              entities including corporate customers, backed by team of highly
              motivated, young and dynamic professionals.
            </p>
          </div>
          <div className="image-container">
            <img src={introImg} alt="" />
          </div>
          <div className="inner-wrapper">
            <div className="message-box">
              <p className="title d-flex justify-content-center align-items-center">
                Our Mission
              </p>
              <div className="text-container d-flex  align-items-center bg-white flex-grow-1">
                {" "}
                <p className="">
                  To establish the company as trustworthy and excellent finance
                  company by gaining the trust of customers.
                </p>
              </div>
            </div>
            <div className="message-box ">
              <p className="title d-flex justify-content-center align-items-center">
                Our Vision
              </p>
              <p className="d-flex justify-content-center align-items-center bg-white flex-grow-1">
                To establish the company as the best financial institution in
                the financial industry by providing prompt and easy processing
                service to the valuable customer.
              </p>
            </div>
          </div>
          <div className="text-list pt-5 pb-5">
            <div className="header-box">
              <p className="title">Our Objective</p>
            </div>
            <ul>
              <li>
                Serving the customer by inducing modern and contemporary
                financial product.
              </li>
              <li>To provide the high rate of return to the depositor.</li>
              <li>To provide competitive rate to the borrower.</li>
              <li>To provide appropriate counseling for the needy customer.</li>
              <li>Quick servicing policy.</li>
              <li>
                Social obligation, transparency and trustworthy finance company.
              </li>
              <li>Contribution to national economic growth.</li>
              <li>Maximizing shareholder wealth.</li>
              <li>Complying with all the policies of regulatory bodies.</li>
              <li>
                Providing healthy working environment, challenging career,
                learning and growth opportunities to employee.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
