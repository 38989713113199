import React, { useState } from "react";
import { Link } from "react-router-dom";
// import deposite2 from "../../images/deposit-02.svg";
import arrowDown from "../../images/arrow-down.svg";
import loan from "../../images/loan.svg";
// import fixedDeposit from "../../images/fixed deposit-01.svg";
import appstore from "../../images/download-appstore.png";
import google from "../../images/download-google.png";
import gurkhaBg from "../../images/gukkha-bg.png";
import productImage from "../../images/bull.png";
export default function RateCharges() {
  const [savingState, setSavingState] = useState(false);
  const [loanState, setLoanState] = useState(false);
  const [fixedDepositState, setFixedDepositeState] = useState(false);
  const handleSaving = () => {
    if (savingState) {
      setSavingState(false);
    } else {
      setSavingState(true);
    }

    setLoanState(false);
    setFixedDepositeState(false);
  };
  const handleLoan = () => {
    if (loanState) {
      setLoanState(false);
    } else {
      setLoanState(true);
    }
    setSavingState(false);

    setFixedDepositeState(false);
  };
  const handlFixedDeposit = () => {
    if (fixedDepositState) {
      setFixedDepositeState(false);
    } else {
      setFixedDepositeState(true);
    }
    setSavingState(false);
    setLoanState(false);
  };

  return (
    <section className="section-rate-charges">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            {/* <div className="rate-charges-container">
              <div className="box-header">
                <h5>Rates and Charges</h5>
              </div>
              <div className="rate-charges-detail">
                <div
                  className={`saving-container common-container ${
                    savingState ? " active" : ""
                  }`}
                  onClick={handleSaving}
                >
                  <div className="saving-details common-details">
                    <div className="logo-container ">
                      <img src={deposite2} alt="" />
                    </div>
                    <div className="text-container">
                      <p>Savings</p>
                    </div>
                    <div className="arrow-container">
                      <img src={arrowDown} alt="" />
                    </div>
                  </div>
                  <div className="saving-dropdown common-dropdown">
                    <table>
                      <tbody>
                        <tr>
                          <td>Fixed Deposit</td>
                          <td>5%</td>
                        </tr>
                        <tr>
                          <td>Normal Deposit</td>
                          <td>5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    loanState
                      ? "loan-container common-container active"
                      : "loan-container common-container"
                  }
                  onClick={handleLoan}
                >
                  <div className="loan-details common-details">
                    <div className="logo-container">
                      <img src={loan} alt="" />
                    </div>
                    <div className="text-container">
                      <p>Loans</p>
                    </div>
                    <div className="arrow-container">
                      <img src={arrowDown} alt="" />
                    </div>
                  </div>
                  <div className="loan-dropdown common-dropdown">
                    <table>
                      <tbody>
                        <tr>
                          <td>Fixed Deposit</td>
                          <td>Up to 7%</td>
                        </tr>
                        <tr>
                          <td>Normal Deposit</td>
                          <td>Up to  7%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    fixedDepositState
                      ? "deposit-container common-container active"
                      : "deposit-container common-container"
                  }
                  onClick={handlFixedDeposit}
                >
                  <div className="deposit-details common-details">
                    <div className="logo-container">
                      <img src={fixedDeposit} alt="" />
                    </div>
                    <div className="text-container">
                      <p>Fixed Deposit</p>
                    </div>
                    <div className="arrow-container">
                      <img src={arrowDown} alt="" />
                    </div>
                  </div>
                  <div className="saving-dropdown common-dropdown">
                    <table>
                      <tbody>
                        <tr>
                          <td>Fixed Deposit</td>
                          <td>Up to  15%</td>
                        </tr>
                        <tr>
                          <td>Normal Deposit</td>
                          <td>Up to  15%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="misc-link a-container">
                <Link to="#">View all rates </Link>
              </div>
            </div> */}
            <div className="image-container custome-image-container">
              <img src={productImage} alt="Product" />
            </div>
          </div>
          <div className="col-md-7">
            <div
              className="mobile-card"
              style={{ backgroundImage: `url(${gurkhaBg})` }}
            >
              <div className="box-header">
                <h2>Transaction made easy with Gurkhas Mobile Banking.</h2>
              </div>
              <div className="box-body">
                <div className="text-container">
                  <h4>Now manage your money digitally.</h4>
                </div>
                <div className="image-container-collection">
                  <div className="image-container">
                    <a
                      href="https://apps.apple.com/us/app/gurkhas-smart/id1269458364"
                      target="blank"
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.f1soft.gurkhasmobilefinance&hl=en"
                    target="blank"
                  >
                    <div className="image-container">
                      <img src={google} alt="" />
                    </div>
                  </a>
                </div>
                {/* <div className="misc-link a-container">
                  <Link to="#">Learn More </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
