import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, GoogleApiWrapper, Map } from "google-maps-react";
import gridViewData from "./GridViewData.json"
import { useParams } from "react-router-dom";
export function Maps({ google, locations = [], setMapData }) {
  // locations = [
  //   {Longitude:85.32726351744384,Latitude:27.70799486109596},
  //   {Longitude:85.36171552683368,Latitude:27.720647608475396},
  //   {Longitude:87.70262453751957,Latitude:26.66433220553426},
  //   {Longitude:87.28553819540559,Latitude:26.811765164020766},
  //   {Longitude:85.3112037666142,Latitude:27.703321938632968},
  //   {Longitude:87.2748337611024,Latitude:26.664034507219764},
  //   {Longitude:87.99198278714493,Latitude:26.643775867355494},
  //   {Longitude:85.3220197570165,Latitude:27.655723761193826},
  //   {Longitude:84.42773942336089,Latitude:27.689266528257342},
  //   {Longitude:85.42779062040267,Latitude:27.665442402249255},
  //   {Longitude:87.68884126669616,Latitude:26.93123916149421},
  //   {Longitude:85.51404655414149,Latitude:27.631889119603766},
  //   {Longitude:80.74102942473144,Latitude:28.77196217368367},
  //   {Longitude:82.34064523703307,Latitude:28.113204969887256},
  //   {Longitude:83.46231286055286,Latitude:27.551189692921454},
  //   {Longitude:87.32096020704944,Latitude:26.859878595286386},
  //   {Longitude:86.42038170820899,Latitude:26.737840461578},
  //   {Longitude:83.98661230332095,Latitude:28.22410981911348},
  //   {Longitude:86.97313134199828,Latitude:27.205437973546346},
  //   {Longitude:81.61608402258673,Latitude:28.594060695778246},
  //   {Longitude:81.63031933239006,Latitude:28.070179567359055},
  //   {Longitude:84.87971280342799,Latitude:27.014419392605905},
  //   {Longitude:85.3153730792236,Latitude:27.73529796863753},
  //   {Longitude:86.87860781405641,Latitude:26.810723472577447},
  //   {Longitude:85.03029273015528,Latitude:27.425401628939913},
  //   {Longitude:85.46296258240163,Latitude:26.9994503770864},
  //   {Longitude:85.34275567278516,Latitude:27.678974799550097},]
  const [clickState, setClickState] = useState();
  const { id } = useParams();



  const handleClick = (name) => {
    setMapData(name);
  }
  return (
    <Map
      google={google}
      containerStyle={{
        width: "100%",
        height: "560px",
        // position: "relative",
      }}
      // style={{
      //   width: "100%",
      //   height: "100%",
      // }}
      center={{
        lat: 27.70788968970718,
        lng: 85.32737242768034,
      }}
      initialCenter={{
        lat: 27.70788968970718,
        lng: 85.32737242768034,
      }}
      zoom={7}
      disableDefaultUI={true}
    >
      {id == "branches" && gridViewData[0].branch?.map((item, index) =>
        <Marker

          key={index}
          position={{
            lat: item.lat,
            lng: item.lng,
          }}
          onClick={() => handleClick(item.branchName)}
        />
      )}
      {id == "atm" && gridViewData[1].atm?.map((item, index) =>
        <Marker

          key={index}
          position={{
            lat: item.lat,
            lng: item.lng,
          }}
          onClick={() => handleClick(item.branchName)}
        />
      )}

    </Map>
  );
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC3fXb_Y-_vMeOO93f0MUqXYJN7hj_qhNE",
})(Maps);
