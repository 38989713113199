import React, { useEffect } from "react";
import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";

const Faq = () => {
  useEffect(() => {
    document.title = "FAQs";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "FAQs"],
    bannerTitle: "FAQs",
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <div
        className="section-notice"
        style={{ backgroundColor: "white", marginTop: "50px" }}
      >
        <div className="container">
          <div className="text-container">
            <div className="header-box">
              <p className="title">FAQs</p>
            </div>
            <p style={{ marginTop: "50px" }}>Coming Soon</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Faq;
