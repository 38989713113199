import React, { useEffect } from "react";
import { useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
import SecondaryBanner from "../../Components/SecondaryBanner";

const AgmMinute = () => {
  useEffect(() => {
    document.title = "AGM Minute";
    window.scrollTo(0, 0);
  }, []);
  const initialState = {
    selectedYear: "all",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "AGM Minute"],
    bannerTitle: "AGM Minute",
  };
  const cardData = [
    {
      id: 6,
      title: "AGM Minute 2079/2080",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2079-80.pdf",
      year: "2079/80",
    },
    {
      id: 5,
      title: "AGM Minute 2077/2078",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2077-78.pdf",
      year: "2077/78",
    },
    {
      id: 4,
      title: "AGM Minute 2076/2077",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2076-77.pdf",
      year: "2076/77",
    },
    {
      id: 3,
      title: "AGM Minute 2075/2076",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2075-076.pdf",
      year: "2075/76",
    },
    {
      id: 2,
      title: "AGM Minute 2074/2075",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2074-075.pdf",
      year: "2074/75",
    },
    {
      id: 1,
      title: "AGM Minute 2073/2074",
      link: "https://gurkhasfinance.com.np/assets/AGM-Minute/2073-74.pdf",
      year: "2073/74",
    },
  ];
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                onClick={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section class="section-report">
        <div class="container">
          <div class="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    )
                )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default AgmMinute;
