import React from "react";
import { Link } from "react-router-dom";

const FixedRelatedProduct = ({ pageID }) => {
  return (
    <div className="related-products">
      <div className="header-box">
        <div className="title" style={{ marginBottom: "35px" }}>
          <p>
            <strong>Related Products</strong>
          </p>
        </div>
      </div>
      <ul>
        {pageID !== "individual" && (
          <li>
            <Link to="/fixed-deposit/individual">Individual Fixed Deposit</Link>
          </li>
        )}
        {pageID !== "institutional" && (
          <li>
            <Link to="/fixed-deposit/institutional">
              Institutional Fixed Deposit
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default FixedRelatedProduct;
