import React from "react";

const DSLAutoRickshaw = () => {
  return (
    <div className="savings-information fade-in">
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div> */}
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Purpose</p>
          </div>
          <ul>
            <li>
              To meet capital requirement to purchase Auto Rickshaw/ Electronic
              Rickshaw operating for self-employment.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>
              In case of additional collateral i.e. land/ land & building
              available: 90% of tax invoice value of financing Rickshaw
            </li>
            <li>
              Concession of interest by 1% may be provided to Dalit, Mukta
              Kamaiya, Haliya, Badi, single women, differently abled people,
              conflict victim, earthquake victims, senior citizen, low cast and
              marginalized people than published rate.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Copy of Citizenship certificate of borrower/guarantor(s).</li>
            <li>
              Properly filled loan application form as prescribed by Finance.
            </li>
            <li>
              Security documents such as credit facility offer letter,
              promissory note, loan deed, personal guarantee, and hire purchase
              agreement as prescribed by finance.
            </li>
            <li>
              Registration charge on Rickshaw financed under this product.
            </li>
            <li>Comprehensive Insurance policy in favor of finance.</li>
            <li>
              Corporate and Buyback agreement of Supplier/Dealer as far as
              practicable in case of additional securities in form of land and
              building.
            </li>
            <li>
              Copy of Land Ownership Registration Certificate (Lalpurja) Land
              Revenue paid receipt, four boundary certificate, blue print and
              trace map.
            </li>
            <li>
              Registration mortgage of additional security in form of land and
              building.
            </li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default DSLAutoRickshaw;
