import React from 'react'
import Slider from 'react-slick'
import sliderImage from '../../images/mobile 1920x400.png'
import onekotwobanner from '../../images/1 ko 2 1920x400.png'
import onekobanner from '../../images/Gurkha 1920x400.png'
import pensionbanner from '../../images/slide yansing sir 1920x400.png'
import naribachatbanner from '../../images/nari 1920x400.png'
import oneko2 from '../../images/1ko2new.jpg'

import scooter from '../../images/scooterbanner.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const HeroSlider = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    speed: 1000,
    // autoplay: 3000,
    arrows: false,
  }
  return (
    <section className="hero-slider">
      <Slider {...settings} className="slick-slider-container">
        <div className="slick-slider-item">
          <div className="image-container">
            <img src={oneko2} alt="" />
          </div>
        </div>
        {/* <div className="slick-slider-item">
          <div className="image-container">
            <img src={onekotwobanner} alt="" />
          </div>
        </div> */}
        <div className="slick-slider-item">
          <div className="image-container">
            <img src={onekobanner} alt="" />
          </div>
        </div>
        <div className="slick-slider-item">
          <div className="image-container">
            <img src={pensionbanner} alt="" />
          </div>
        </div>
        {/* <div className="slick-slider-item">
          <div className="image-container">
            <img src={naribachatbanner} alt="" />
          </div>
        </div> */}
        <div className="slick-slider-item">
          <div className="image-container">
            <img src={sliderImage} alt="" />
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default HeroSlider
