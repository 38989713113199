
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "./Table";
import SecondaryBanner from "../../Components/SecondaryBanner";

export default function CapitalShareHolder() {
    const [bannerData, setBannerData] = useState({
        breadcrumb: ["Home", "About Us", "Capital Structure"],
        bannerTitle: "Capital Structure",
      });
  return (
    <>
    {bannerData && <SecondaryBanner bannerData={bannerData} />}
      <Table />
        </>
  )
}
