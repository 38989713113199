import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import DSLAutoRickshaw from "./DSLAutoRickshaw";
import LaghuKarja from "./LaghuKarja";

const MicroLoan = () => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  function getPageTitle(pageID) {
    if (pageID === "laghu-karja") {
      document.title = "Laghu Karja";
      return "Laghu Karja";
    } else if (pageID === "dsl-auto-rickshaw") {
      document.title = "DSL Auto Rickshaw";
      return "DSL Auto Rickshaw";
    }
  }
  const bannerData = {
    breadcrumb: [
      "Home",
      "Explore Products",
      "Loan and Advance",
      "Micro Loan",
      getPageTitle(id),
    ],
    bannerTitle: getPageTitle(id),
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-savings">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              {id === "laghu-karja" && <LaghuKarja />}
              {id === "dsl-auto-rickshaw" && <DSLAutoRickshaw />}
            </div>
            <div className="col-md-3">
              <div className="related-products">
                <div className="header-box">
                  <div className="title" style={{ marginBottom: "35px" }}>
                    <p>
                      <strong>Related Products</strong>
                    </p>
                  </div>
                </div>
                <ul>
                  {id !== "laghu-karja" && (
                    <li>
                      <Link to="/micro-loan/laghu-karja">Laghu Karja</Link>
                    </li>
                  )}
                  {id !== "dsl-auto-rickshaw" && (
                    <li>
                      <Link to="/micro-loan/dsl-auto-rickshaw">
                        DSL Auto Rickshaw
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MicroLoan;
