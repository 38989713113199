import React, { useEffect } from "react";
import { useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
import SecondaryBanner from "../../Components/SecondaryBanner";

const AnnualReport = () => {
  useEffect(() => {
    document.title = "Anual Report";
    window.scrollTo(0, 0);
  }, []);
  const initialState = {
    selectedYear: "all",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "Annual Report"],
    bannerTitle: "Annual Report",
  };
  
  const cardData = [
    {
      id: 5,
      title: "Annual Report 2078/79 & 2079/2080",
      link: "https://gurkhasfinance.com.np/assets/annual-report/Annual Report 2078-79-80.pdf",
      year: "2078/79",
    },
    {
      id: 1,
      title: "Annual Report 2077/2078",
      link: "https://gurkhasfinance.com.np/assets/annual-report/Annual Report-2077_78.pdf",
      year: "2077/78",
    },
    {
      id: 2,
      title: "Annual Report 2076/2077",
      link: "https://gurkhasfinance.com.np/assets/annual-report/Annual Report-2077.pdf",
      year: "2076/77",
    },
    {
      id: 3,
      title: "Annual Report 2075/2076",
      link: "https://gurkhasfinance.com.np/assets/annual-report/Annual Report-2075_76.pdf",
      year: "2075/76",
    },
    {
      id: 4,
      title: "Annual Report 2074/2075",
      link: "https://gurkhasfinance.com.np/assets/annual-report/annual report 2074_75.pdf",
      year: "2074/75",
    },
    // {
    //   id: 5,
    //   title: "Annual Report 2073/2074",
    //   link: "#",
    //   year: "2073/74",
    // },
    // {
    //   id: 6,
    //   title: "Annual Report 2072/2073",
    //   link: "#",
    //   year: "2072/73",
    // },
    // {
    //   id: 7,
    //   title: "Annual Report 2071/2072",
    //   link: "#",
    //   year: "2071/72",
    // },
    {
      id: 5,
      title: "Annual Report 2070/2071",
      link: "https://gurkhasfinance.com.np/assets/annual-report/annual report 2071.pdf",
      year: "2070/71",
    },
  ];
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-sub-header">
        <div className="container">
          <div className="content">
            <div className="location-dropdown">
              <select
                id="year-dropdown"
                onClick={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
                <option value="2072/73">2072/73</option>
                <option value="2071/72">2071/72</option>
                <option value="2070/71">2070/71</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                <CardDownload
                  cardTitle={card.title}
                  link={card.link}
                  key={card.id}
                />
              ))
              : cardData.map(
                (card) =>
                  card.year === state.selectedYear && (
                    <CardDownload
                      cardTitle={card.title}
                      link={card.link}
                      key={card.id}
                    />
                  )
              )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default AnnualReport;
