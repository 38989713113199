import React from "react";

const MarginLending = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Margin lending is a type of loan that allows you to borrow money to
            invest, by using your existing shares, managed funds, and/or cash as
            security. It’s a type of gearing which is borrowing money to invest.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>Financing against owned shares.</li>
            <li>For purchasing of share</li>
            <li>Financing up-to 70% of the share value.</li>
            <li>Maximum limit up-to 40 Million</li>
            <li>Hassle Free.</li>
            <li>Quick Processing Time.</li>
          </ul>
        </div>
      </div>

      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Passport size photo of applicants/guarantor.</li>
            <li>Copy of citizenship document of borrowers/guarantor</li>
            <li>DMAT Statements.</li>
            <li>PAN in case limit above 5 Million.</li>
            <li>
              Registration Document at the concerned Nepal Government Office and
              Tax Office (for firms/companies).
            </li>
            <li>
              Memorandum of Articles and Association (if Private Limited).
            </li>
            <li>Partnership Deed (for Partnership firm).</li>
            <li>Board Resolution (if Private Limited and Institutions).</li>
            <li>
              At least 2 years past audited financials for business income.
            </li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default MarginLending;
