import React from "react";

const LoanAgainstFDR = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            When you invest in a bank fixed deposit, you can easily get a loan
            against it without having to break it.
          </p>
        </div>
        <div className="sub-text-container">
          <ul>
            <li>
              <strong>Interest Rate</strong>: Coupon Rate plus upto 2%
            </li>
            <li>
              <strong>Limit</strong>: As per FD amount
            </li>
            <li>
              <strong>LTV</strong>: 90% for Funded limit and 100% for non-Funded
              limit.
            </li>
            <li>
              <strong>Interest Payment Modality</strong>: Monthly or Quarterly
              (As per Interest Posting frequency of FD)
            </li>
            <li>
              <strong>Facility Types</strong>: Overdraft or Demand Loan
            </li>
            <li>
              <strong>Premature of FD</strong>: Premature shall be allowed only
              after settlement of loans and its dues.
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div> */}
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default LoanAgainstFDR;
