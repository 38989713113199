import avatar from "../../images/avatar.webp";
import gurkha from "../../images/gurkha-square-log.png";
import sunita from "../../images/sunita-rai.jpg";
import nilamRai from "../../images/neelam-rai.jpg";
import rajinRai from "../../images/rajin-rai.jpg";
import asharamsah from "../../images/sunita-rai.jpg";
import nirojmaharjan from "../../images/niroj-maharjan.jpg";
import JeevanKumarRai from "../../images/manager/jeevan-kumar-rai.JPG";
import sushilKoirala from "../../images/sushil.jpg";
import dhirajGurung from "../../images/dhiraj-gurung.jpg";
import rohitaIngam from "../../images/rohita-ingam.jpg";
import pramilaChaulagain from "../../images/manager/pramila-chaulagain.jpg";
import shovaBajracharya from "../../images/shova-bajracharya.jpg";
import renukaMagar from "../../images/Renuka_magar.jpg";
import prasannaSubba from "../../images/Prasanna.jpg";
import shantiSingh from "../../images/shanti.jpg";
import ajayaChaudhari from "../../images/ajay_chaudhary.jpg";
import ramBahadurRawal from "../../images/ram-bahadur-rawal.jpg";
import prakashBhattarai from "../../images/prakash-bhattarai .jpg";
import sumanSubba from "../../images/suman-subba.jpg";
import umaRajGurung from "../../images/uma-raj-gurung.jpg";
import harathiMathema from "../../images/harati-mathema.png";
import surajRaiBhojpur from "../../images/suraj-rai-bhojpur.jpg";
import tapBahadurKhadka from "../../images/tap-bahadur-khadka.bmp";
import mohamadAnsari from "../../images/mohamad-ansari.jpg";
import giridharShahi from "../../images/giridhar-shahi.jpg";
import jiwanKajiKarki from "../../images/kaji.jpg";
import jyotiOmShrestha from "../../images/jyoti-om-shrestha.jpg";
import sameerKamat from "../../images/sameer-kamat.jpg";
import bishwashKhadka from "../../images/bishwash-khadka.jpg";
import kalyaniRai from "../../images/kalyani-rai.jpeg";
import lilaRaj from "../../images/lila-raj-thumbapo.jpg";
import ratnaBahadurThapa from "../../images/ratna-bahadur-thapa.jpg";
import pramodKumar from "../../images/Pramod Kumar Jha.jpg";
import manishAcharya from "../../images/manish acharya.jpg";
import rajeshJha from "../../images/rajesh Jha.jpg";
import rajan from "../../images/Rajan.jpg";
import susilKoirala from "../../images/susilKoirala.png";
import imagePlaceHolder from "../../images/gurkha-square-log.png";
import GopalPaneru from "../../images/GopalPaneru.jpg";
import KKL from "../../images/KLKH.JPG";
import PrakashPanta from "../../images/manager/prakash-panta.jpg";
import NareshLalJoshi from "../../images/manager/naresh-lal-joshi.jpg";
import NagendraLawati from "../../images/manager/nagendra-lawati.jpg";
import MadhabBhattarai from "../../images/madhab_bhattarai.jpg";
import ramprasadlahogun from "../../images/ram_prasad_lohagun.jpg";

// import avatar from "../../images/avatar.webp";

// import prakashBhattarai from "../../images/prakash-bhattarai .jpg";
// import mohamadAnsari from "../../images/mohamad-ansari.jpg";
// import jyotiOmShrestha from "../../images/jyoti-om-shrestha.jpg";
// import sameerKamat from "../../images/sameer-kamat.jpg";
// import kalyaniRai from "../../images/kalyani-rai.jpeg";
// import lilaRaj from "../../images/lila-raj-thumbapo.jpg";
import dilipKumarMandal from "../../images/dilip-kumar-mandal.png";

export const data = [
  {
    OfficeLocation: "Head Office",
    name: "Susil Koirala",
    image: susilKoirala,
    location: "Dillibazar, Kathmandu",
    province: "Bagmati Province ",
    phone: "01-4537401, 01-4537403, 01-4529060,",
    email: "info@gurkhasfinance.com.np",
  },

  {
    OfficeLocation: "Boudha",
    name: "Nilam Rai",
    image: nilamRai,
    province: "Bagmati Province ",
    phone: "01-4561155, 01-4590522",
    email: "boudha@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Damak",
    name: "Rajin Rai",
    image: rajinRai,
    province: "Province 1",
    phone: "023-575389",
    email: "damak@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Dharan",
    name: "Jeevan Kumar Rai",
    image: JeevanKumarRai,
    province: "Province 1",
    phone: "025-538789, 025-538790, 025-538791",
    email: "dharan@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Newroad",
    name: "Naresh Lal Joshi",
    image: NareshLalJoshi,
    province: "Bagmati Province",
    phone: "01-5713241, 01-5713041, 01-5713228",
    email: "newroad@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Itahari",
    name: "Dhiraj Kumar Gurung",
    image: dhirajGurung,
    province: "Province 1",
    phone: "025-586928",
    email: "itahari@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Birtamode",
    name: "Nomita Thapa",
    image: gurkha,
    province: "Province 1",
    phone: " 023-536421",
    email: "birtamode@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Satdobato",
    name: "Sunita Rai",
    image: sunita,
    province: "Bagmati Province",
    phone: "01-5151232, 01-5151247",
    email: "satdobato@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Bhaktapur",
    name: "Pramila Chaulagain",
    image: pramilaChaulagain,
    province: "Bagmati Province",
    phone: "01-6619268, 01-6619267",
    email: "bhaktapur@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Rabi",
    name: "Nagendra Lawati",
    image: NagendraLawati,
    province: "Province 1",
    phone: "024-412107, 024-412108",
    email: "rabi@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Narayanghat",
    name: "Prakash Panta",
    image: PrakashPanta,
    province: "Bharatpur Height, Chitwan",
    phone: "056-523850/9855011955",
    email: "narayangadh@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Banepa",
    name: "Shova Bajracharya",
    image: shovaBajracharya,
    province: "Bagmati Province",
    phone: "011-663148",
    email: "janagal@gurkhasfinance.com.np",
  },

  {
    OfficeLocation: "Chaumala",
    name: "Gopal Paneru",
    image: GopalPaneru,
    province: "Sudurpashchim Province ",
    phone: " 091-418052",
    email: "chaumala@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Bijauri",
    name: "Ajaya Chaudhari",
    image: ajayaChaudhari,
    province: "Lumbini Province",
    phone: "082-411126",
    email: "bijauri@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Lakhanchowk",
    name: "Ram Prasad Lahagun",
    image: ramprasadlahogun,
    province: "Lumbini Province",
    phone: "071-429346",
    email: "lakhanchowk@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Bhedetar",
    name: "Suman Subba",
    image: sumanSubba,
    province: "Province 1",
    phone: "025-400064",
    email: "bhedetar@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Chhapradi",
    name: "Asha Ram Sah",
    image: gurkha,
    province: "Madhesh Province",
    phone: "9852831520",
    email: "chapradi@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Pokhara",
    name: "Sundar Pokharel",
    image: gurkha,
    province: "Gandaki Province",
    phone: "061-544984/85",
    email: "pokhara@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Bhojpur",
    name: "Suraj Rai",
    image: surajRaiBhojpur,
    province: "Province 1",
    phone: "9851212379, 9813812307",
    email: "bhojpur@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Surkhet",
    name: "Tap Bahadur Khadka",
    image: tapBahadurKhadka,
    province: "Karnali Province",
    phone: "083-524932",
    email: "surkhet@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Nepalgunj",
    name: "Rohit Chand",
    image: KKL,
    province: "Lumbini Province",
    phone: "081-535003, 081-535004",
    email: "nepalgunj@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Birgunj",
    name: "Shanti Kumari Singh",
    image: gurkha,
    province: "Madhesh Province",
    phone: "051-531479, 051-534479",
    email: "birgunj@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Gongabu",
    name: "Niroj Maharjan",
    image: nirojmaharjan,
    province: "Bagmati Province",
    phone: " 01-4964132,01-4383708",
    email: "gongabu@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Beltar",
    name: "Renuka Magar",
    image: renukaMagar,
    province: "Province 1, Udayapur",
    phone: "035-440053, 9849322391",
    email: "beltar@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Hetauda",
    name: "Rajan Silpakar",
    image: rajan,
    province: "Bagmati Province",
    phone: " 057-590524, 057-590525, 9841185539",
    email: "hetauda@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Barahathawa",
    name: " Dilip Kumar Mandal",
    image: dilipKumarMandal,
    province: "Madhesh Province",
    phone: "046-540248, 9844107612, 9817800111",
    email: "barahathawa@gurkhasfinance.com.np",
  },
  {
    OfficeLocation: "Koteshwor Exension Counter",
    name: "Prasanna Subba Pomo",
    image: prasannaSubba,
    province: "Bagmati Province",
    phone: "01-4610764, 01-4610765",
    email: "",
  },
];
