import React, { useEffect, useState } from "react";
import {
  auditCommittee,
  riskManagement,
  staffFacilities,
  amlCftCommittee,
  executiveLevel,
  headOfDepartment,
} from "./BoardLevelCommiteeData";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
export default function BoardLevelCommittee() {
  const { id } = useParams();
  const [mapData, setMapData] = useState();
  const [boxHeader, setBoxHeader] = useState();
  const [bannerData, setBannerData] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    switch (id) {
      case "audit-committee":
        document.title = "Board of Directors";
        setMapData(auditCommittee);
        setBoxHeader("Audit Committee");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Board Level Committee",
              "Audit Committee",
            ],
            bannerTitle: "Board of Directors",
          },
        });

        break;

      case "risk-management":
        document.title = "Risk Management";
        setMapData(riskManagement);
        setBoxHeader("Risk Management");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Board Level Committee",
              "Risk Management",
            ],
            bannerTitle: "Board of Directors",
          },
        });

        break;
      case "staff-facilities":
        document.title = "Staff Facilities";
        setMapData(staffFacilities);
        setBoxHeader("Staff Facilities");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Board Level Committee",
              "Staff Facilities",
            ],
            bannerTitle: "Board of Directors",
          },
        });

        break;
      case "aml-cft-committee":
        document.title = "AML-CFT Committee";
        setMapData(amlCftCommittee);
        setBoxHeader("AML-CFT Committee");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Board Level Committee",
              "AML-CFT Committee",
            ],
            bannerTitle: "Board of Directors",
          },
        });

        break;
      case "executive-level":
        document.title = "Executive Level";
        setMapData(executiveLevel);
        setBoxHeader("Executive Level");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Management Team",
              "Executive Level",
            ],
            bannerTitle: "Management Team",
          },
        });

        break;
      case "head-of-department":
        document.title = "Head of Department";
        setMapData(headOfDepartment);
        setBoxHeader("Head of Department");
        setBannerData({
          banner: {
            breadcrumb: [
              "Home",
              "About Us",
              "Management Team",
              "Head of Department",
            ],
            bannerTitle: "Management Team",
          },
        });
        break;
      default:
        break;
    }
  }, [id]);
  return (
    <>
      {bannerData.banner && <SecondaryBanner bannerData={bannerData.banner} />}

      {/* <SecondaryBanner/> */}
      <section className="section-gallery">
        <div className="container">
          <div className="content">
            <div className="box-header">
              <h3>{boxHeader}</h3>
            </div>
            <div className="row-multiple">
              {mapData?.map((item, index) => (
                <div className="card-person" key={index}>
                  <div className="image-container">
                    <img src={item.image} alt="" />
                  </div>
                  <p className="caption-big">{item.name}</p>
                  <p className="caption-small">{item.position}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
