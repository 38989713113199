import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import Introduction from "./Pages/Introduction/Introduction";
import Footer from "./Components/Footer/Footer";
import StickyButton from "./Components/Helper/StickyButton";
import BoardOfDirectors from "./Pages/BoardOfDirectors/BoardOfDirectors";
import BoardLevelCommittee from "./Pages/BoardLevelCommitteeAndManagementTeam/BoardLevelCommittee";
import CompanyAndInformationOfficer from "./Pages/CompanyAndInformationOfficer/CompanyAndInformationOfficer";
import BranchManagers from "./Pages/BranchManagers/BranchManagers";
import CapitalShareHolderBankingHour from "./Pages/CapitalShareHolderBankingHour/CapitalShareHolderBankingHour";
import TransactionalBanking from "./Pages/TransactionalBanking/TransactionalBanking";
import RetailLoan from "./Pages/RetailLoan/RetailLoan";
import Saving from "./Pages/Saving/Saving";
import BusinessLoan from "./Pages/BusinessLoan/BusinessLoan";
import MicroLoan from "./Pages/MicroLoan/MicroLoan";
import FixedDeposit from "./Pages/FixedDeposit/FixedDeposit";
import OtherDeposit from "./Pages/OtherDeposits/OtherDeposit";
import AgmMinute from "./Pages/AgmMinute/AgmMinute";
import BaselDisclosure from "./Pages/BaselDisclosure/BaselDisclosure";
import { MajorFinancialHighlight } from "./Pages/MajorFinancialHighlight/MajorFinancialHighlight";
import TrariffCharges from "./Pages/TariffCharges/TrariffCharges";
import BranchesAtm from "./Pages/BranchesAtm/BranchesAtm";
import PriceSensitiveInformation from "./Pages/PriceSensitiveInformation/PriceSensitiveInformation";
import RightToInformation from "./Pages/RightToInformation/RightToInformation";
import BaseRate from "./Pages/BaseRateAndNetSpreadInterest/BaseRate";
import QuarterlyReport from "./Pages/QuarterlyReport/QuarterlyReport";
import AnnualReport from "./Pages/AnnualReport/AnnualReport";
import Notice from "./Pages/Notices/Notice";
import EmiFd from "./Pages/Home/EmiFd";
import InterestRate from "./Pages/InterestRates/InterestRate";
import Career from "./Pages/Career/Career";
import Download from "./Pages/Download/Download";
import Faq from "./Pages/FAQ/Faq";
import PromoterShareHolder from "./Pages/CapitalShareHolderBankingHour/PromoterShareHolder";
import SubsuduzedLoanDisClosure from "./Pages/SubsuduzedLoanDisClosure/SubsuduzedLoanDisClosure";
import RefinanceLoanDisClosure from "./Pages/RefinanceLoanDisClosure/RefinanceLoanDisClosure";
import CsrNoticeAndUpdatesPage from "./Pages/Home/CsrNoticeAndUpdatesPage";
// import StickyButtonForexFBStock from "./Components/StickyButtonForexFBStock";
import CapitalShareHolder from "./Pages/CapitalShareHolderBankingHour/CapitalShareHolder";
import { Media } from "./Pages/media/Media";
import StaffTrainingDetails from "./Pages/StaffTrainingDisclosure/StaffTrainingDetails";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/bod" element={<BoardOfDirectors />} />
          <Route path="/blc/:id" element={<BoardLevelCommittee />} />
          <Route path="/mt/:id" element={<BoardLevelCommittee />} />
          <Route path="/ci/:id" element={<CompanyAndInformationOfficer />} />
          <Route path="/branch-manager" element={<BranchManagers />} />
          <Route
            path="/table/:id"
            element={<CapitalShareHolderBankingHour />}
          />
          <Route
            path="/transactional-banking/:id"
            element={<TransactionalBanking />}
          />
          <Route path="/CapitalShareHolder" element={<CapitalShareHolder />} />
          <Route path="/retail-loan/:id" element={<RetailLoan />} />
          <Route path="/saving/:id" element={<Saving />} />
          <Route path="/business-loan/:id" element={<BusinessLoan />} />
          <Route path="/micro-loan/:id" element={<MicroLoan />} />
          <Route path="/fixed-deposit/:id" element={<FixedDeposit />} />
          <Route path="/other-deposit/:id" element={<OtherDeposit />} />
          <Route path="/agm-minute" element={<AgmMinute />} />
          <Route path="/basel-disclosure" element={<BaselDisclosure />} />
          <Route
            path="/major-financial-highlight"
            element={<MajorFinancialHighlight />}
          />
          <Route path="/tariffs" element={<TrariffCharges />} />
          <Route path="/branches&atm/:id" element={<BranchesAtm />} />
          <Route
            path="/price-sensitive-information"
            element={<PriceSensitiveInformation />}
          />
          <Route
            path="/right-to-information"
            element={<RightToInformation />}
          />
          <Route path="/base-net-interest" element={<BaseRate />} />
          <Route path="/quarterly-report" element={<QuarterlyReport />} />
          <Route path="/annual-report" element={<AnnualReport />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/interest-rates" element={<InterestRate />} />
          <Route path="/career" element={<Career />} />
          <Route path="/downloads" element={<Download />} />
          <Route path="/faq" element={<Faq />} />

          <Route path="/emi-calculator" element={<EmiFd />} />
          <Route
            path="/promoter-shareholder"
            element={<PromoterShareHolder />}
          />
          <Route
            path="/subsidized-loan-disclosure"
            element={<SubsuduzedLoanDisClosure />}
          />
          <Route
            path="/staff-training-details"
            element={<StaffTrainingDetails />}
          />
          <Route
            path="/refinance-loan-disclosure"
            element={<RefinanceLoanDisClosure />}
          />
          <Route
            path="/CsrNoticeAndUpdatesPage/:id"
            element={<CsrNoticeAndUpdatesPage />}
          />

          <Route
            path="CsrNoticeAndUpdatesPage/csrList/:id"
            element={<CsrNoticeAndUpdatesPage />}
          />
          <Route
            path="CsrNoticeAndUpdatesPage/noticeUpdate/:id"
            element={<CsrNoticeAndUpdatesPage />}
          />
          <Route path="/media" element={<Media />} />
        </Routes>

        {/* <Introduction/> */}
        <StickyButton />
        {/* <StickyButtonForexFBStock/> */}
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default App;
