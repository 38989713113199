import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "../../Components/Table";
import pageData from "./pageData.json";

export default function IntroTable() {
  const { id } = useParams();
  const { bankingHours, capitalStructure, bankingHours2 } = pageData;
  const mystyle = {
    margin: "10px 0px 0px",
    padding: "10px",
    fontFamily: "Arial"
  };

  return (
    <section className="section-table">
      <div className="container">
        <div className="tables">
          {id === "banking-hours" ? (
            <>
              <div className="box-header" style={mystyle}><p>Magh 16 - Kartik 15</p></div>
              <Table
                tableHeader={bankingHours.tableHeader}
                tableData={bankingHours.tableData}
              />
              <div className="box-header" style={mystyle}><p>Kartik 16 – Magh 15</p></div>
              <Table
                tableHeader={bankingHours2.tableHeader}
                tableData={bankingHours2.tableData}
              />
            </>


          ) : (
            <Table
              tableHeader={capitalStructure.tableHeader}
              tableData={capitalStructure.tableData}
            />
          )}
        </div>
      </div>
    </section>
  );
}
