import React from "react";

const GoldLoan = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Gold loan or loan against gold is a secured loan in which a customer
            pledges his/her gold ornaments as collateral with a gold loan
            company. The finance, in turn, gives a loan amount as per the market
            value of gold to the customer. It is a very quick and easy way of
            fulfilling one’s financial needs as compared to the other loans.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>Facilities Type: Overdraft or Demand Loan</li>
            <li>Purpose: To fulfill personal financing need</li>
            <li>Target Customer: individuals</li>
            <li>
              Tenure: 1 year revolving for overdraft (as per approval for demand
              loan not exceeding 1 year).
            </li>
            <li>Age: 21 years to 70 Years at the time of maturity</li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default GoldLoan;
