import React, { useEffect, useState } from "react";

const FixedDepositCalculator = () => {
  const [principal, setPrincipal] = useState("5000000");
  const [interestRate, setInterestRate] = useState(10);
  const [month, setMonth] = useState(12);
  const [totalAmount, setTotalAmount] = useState(null);
  const [simpleInterest, setSimpleInterest] = useState("");
  const [prevInterestRate, setPrevInterestRate] = useState(10);
  useEffect(() => {
    function getFixedDeposit() {
      setSimpleInterest((principal * interestRate * month) / (12 * 100));
      console.log("simple interest", simpleInterest);
      setTotalAmount(Number(principal) + Number(simpleInterest));
    }
    getFixedDeposit();
  }, [interestRate, month, principal, simpleInterest]);
  // remove commas and npr from principal
  function getPrincipalNumber(e) {
    let plainValue = e.target.value;
    if (
      plainValue &&
      plainValue.match(/\d+/g, "").join("") > Number(e.target.max)
    ) {
      setPrincipal(Number(e.target.max));
    } else if (
      Number(e.target.value) < Number(e.target.min) &&
      e.key === "Enter"
    ) {
      setPrincipal(Number(e.target.min));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setPrincipal(e.target.value);
    } else {
      setPrincipal(plainValue.match(/\d+/g, "").join(""));
    }
  }
  function getInterestNumber(e) {
    let plainValue = e.target.value;
    if (e.target.value === "") {
      setInterestRate("");
    } else if (Number(e.target.value) > Number(e.target.max)) {
      setInterestRate(Number(e.target.max));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setInterestRate(Number(e.target.min));
    } else {
      // setInterestRate(Number(plainValue.match(/\d+/g, "").join("")));
      if (isNaN(e.target.value)) {
        setInterestRate(prevInterestRate);
      } else {
        setInterestRate(e.target.value);

        if (Number(e.target.value) > Number(e.target.max)) {
          setPrevInterestRate(e.target.max)
        } else if (Number(e.target.value) < Number(e.target.min)) {
          setPrevInterestRate(e.target.min)
        }
        else {
          setPrevInterestRate(e.target.value)
        }
      }
    }
  }
  function getMonth(e) {
    let plainValue = e.target.value;
    if (e.target.value === "") {
      console.log(e.target.value);
      setMonth("");
    } else if (Number(e.target.value) > Number(e.target.max)) {
      setMonth(Number(e.target.max));
    } else if (Number(e.target.value) < Number(e.target.min)) {
      setMonth(Number(e.target.min));
    } else {
      setMonth(Number(plainValue.match(/\d+/g, "").join("")));
    }
  }
  return (
    <div className="row loan-content fixed" id="fixed">
      <div className="col-lg-7">
        <div className="emi-fd-left">
          <div className="box-header">
            <h2>Calculate your FD instantly!</h2>
            <h4>Invest money wisely in Fixed Deposit.</h4>
          </div>
          <div className="emi-fd-value-container">
            <div className="fd-loan-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">My Principal Amount</p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="100000"
                    max="100000000"
                    id="fd-loan-slider"
                    className="slider"
                    value={principal}
                    onChange={(e) => setPrincipal(e.target.value)}
                  />
                  <div
                    id="fd-loan-selector"
                    className="selector"
                    style={{ left: `${(principal / 100000000) * 100}%` }}
                  >
                    <div className="loan-selectBtn selectBtn"></div>
                  </div>
                  <div
                    id="fd-loan-progressBar"
                    className="progressBar"
                    style={{
                      width: `${(principal / 100000000) * 100}%`,
                    }}
                  ></div>
                </div>
                <input
                  className="trailStyle"
                  type="text"
                  id="fd-loan-progressBar-info"
                  min="100000"
                  max="100000000"
                  value={Number(principal).toLocaleString("en-IN")}
                  onInput={(e) => {
                    getPrincipalNumber(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getPrincipalNumber(e);
                    }
                  }}
                />
              </div>
            </div>
            <div className="interest-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">Interest Rate (%)</p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="1"
                    max="15"
                    step="0.01"
                    id="fd-interest-slider"
                    className="slider"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                  />
                  <div
                    id="fd-interest-selector"
                    className="selector"
                    style={{ left: `${(interestRate / 15) * 100}%` }}
                  >
                    <div className="interest-selectBtn selectBtn"></div>
                  </div>
                  <div
                    id="fd-interest-progressBar"
                    className="progressBar"
                    style={{ width: `${(interestRate / 15) * 100}%` }}
                  ></div>
                </div>
                <div className="input-value">
                  <label>
                    <input
                      id="fd-interest-progressBar-info"
                      type="text"
                      min="1"
                      max="15"

                      value={interestRate}
                      onChange={(e) => getInterestNumber(e)}
                    />
                    %PA
                  </label>
                </div>
              </div>
            </div>
            <div className="year-value-container emi-fd-value-container-detail">
              <p className="text-label-emi">Tenure(Months)</p>
              <div className="emi-for-flex">
                <div className="main">
                  <input
                    type="range"
                    min="1"
                    max="120"
                    id="month-slider"
                    className="slider"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  />
                  <div
                    id="fd-month-selector"
                    className="selector"
                    style={{ left: `${(month / 120) * 100}%` }}
                  >
                    <div
                      className="year-selectBtn selectBtn"
                      id="fd-year-selector"
                    ></div>
                  </div>
                  <div
                    id="month-progressBar"
                    className="progressBar"
                    style={{ width: `${(month / 120) * 100}%` }}
                  ></div>
                </div>
                <div className="input-value">
                  <label>
                    <input
                      id="month-progressBar-info"
                      type="text"
                      min="1"
                      max="120"
                      value={month}
                      onChange={(e) => getMonth(e)}
                    />{" "}
                    Months
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="emi-fd-right">
          <div className="box-header">
            <h5>Your Amount:</h5>
            <p>
              <span id="fd-total-montly-emi">{`${Number(
                simpleInterest
              ).toLocaleString("en-IN", {
                style: "currency",
                currency: "NPR",
              })}`}</span>
            </p>
          </div>
          <div className="box-body">
            <div className="principle box-body-flex">
              <p>Principal:</p>
              <p>
                <span id="fd-principal-value-dynamic">{`${Number(
                  principal
                ).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "NPR",
                })}`}</span>{" "}
              </p>
            </div>
            <div className="interest-payable  box-body-flex">
              <p>Interest Amount:</p>
              <p>
                <span id="fd-interest-payable-value-dynamic">
                  {`${Number(simpleInterest).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "NPR",
                  })}`}
                </span>
              </p>
            </div>
            <div className="principle box-body-flex">
              <p>Total Amount Return:</p>
              <p>
                <span id="fd-total-payable-value-dynamic">{`${Number(
                  totalAmount
                ).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "NPR",
                })}`}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedDepositCalculator;
