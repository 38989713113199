import React from "react";
import secondaryBannerImage from "../../images/qr.png";

const QrScanAndPay = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            As a paperless system of making payment, Gurkhas Finance Limited
            offers an alternative to the traditional systems, a payment via QR
            Code scanning through Mobile. QR payment systems have the advantage
            of enabling transactions to be processed quickly and more cheaply
            and also offer a much more convenient method of effecting settlement
            of transactions.
            <br />
            Gurkhas offers merchant enrollment in QR payment services offered by
            Fonepay for different business entities.
            <br />
            QR payments are anticipated to be one of the most sought-after
            payment solutions in near future. The Fonepay QR Codes issued by
            GUFL can be used for accepting payments from eSewa and mobile
            banking applications of more than 58 different banks and financial
            institutions.
          </p>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default QrScanAndPay;
