import React from 'react'
import { useEffect, useReducer } from 'react'
import CardReport from '../../Components/CardReport'
import Contact from '../../Components/Contact'
import SecondaryBanner from '../../Components/SecondaryBanner'
import CardDownload from '../../Components/CardDownload'
const Career = () => {
  useEffect(() => {
    document.title = 'Career'
    window.scrollTo(0, 0)
  }, [])
  const bannerData = {
    breadcrumb: ['Home', 'Career'],
    bannerTitle: 'Career',
  }

  const initialState = {
    selectedYear: 'all',
  }
  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeYear':
        return { ...state, selectedYear: action.year }
      default:
        throw new Error('Action type not defined')
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const cardData = [
    // {
    //   id: 1,
    //   title: "Vacancy Shrawan 3,2079",
    //   link: "https://gurkhasfinance.com.np/assets/career/vacancy.pdf",
    //   year: "2078/79",
    // },
    {
      id: 1,
      title: 'Exam Notice and Syllabus-Junior Assistants',
      link:
        'https://gurkhasfinance.com.np/assets/career/Exam Notice and Syllabus-Junior Assistants-2080-01-09_Saturday.pdf',
      year: '2079/80',
    },
    {
      id: 2,
      title: 'Gurkhas Junior Assistant Written Exam Result',
      link:
        'https://gurkhasfinance.com.np/assets/career/Gurkhas Junior Assistant Written Exam Result.pdf',
      year: '2079/80',
    },
  ]
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      {/* <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                id="year-dropdown"
                onClick={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
              </select>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === 'all'
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    ),
                )}
          </div>
        </div>
      </section>
      {/* <div
        className="section-notice"
        style={{ backgroundColor: "white", marginTop: "50px" }}
      >
        <div className="container">
          <div className="text-container">
            <div className="header-box">
              <p className="title">Vacancy</p>
            </div>
          </div>
          <div className="image-container">
            <img
              src="https://www.gurkhasfinance.com.np/ckfinder/userfiles/files/Career%20Opportunity%20final%20-%20July%2017%2C%202022%20%20-1.jpg"
              alt=""
            />
          </div>
        </div>
      </div> */}
      {/* <section class="section-report">
        <div class="container">
          <div class="card-row">
            <CardReport
              cardTitle={"Vacancy Announcement"}
              link={
                "https://www.gurkhasfinance.com.np/ckfinder/userfiles/files/Career%20Opportunity%20final%20-%20July%2017%2C%202022%20%20-1.jpg"
              }
            />
          </div>
        </div>
      </section> */}
    </main>
  )
}

export default Career
