import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import Contact from "../../Components/Contact";
import Gallery from "./Gallery";
export default function BoardOfDirectors() {
  useEffect(() => {
    document.title = "Board of Directors";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "About Us", "Board of Directors"],
    bannerTitle: "Board of Directors",
  };
  return (
    <main class="page-bod">
      <SecondaryBanner bannerData={bannerData} />
      <Gallery />
    </main>
  );
}
