import React, { useEffect } from "react";
import { useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
// import CardReport from "../../Components/CardReport";
// import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";

const QuarterlyReport = () => {
  const initialState = {
    selectedYear: "all",
  };
  useEffect(() => {
    document.title = "Quarterly Report";
    window.scrollTo(0, 0);
  }, []);
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "Quarterly Report"],
    bannerTitle: "Quarterly Report",
  };
  const cardData = [
    {
      id: 36,
      title: "1st Quarter Report (2081/82)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2081-82).pdf",
      year: "2081/82",
    },
    {
      id: 35,
      title: "4th Quarter Report (2080/81)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2080-81).pdf",
      year: "2080/81",
    },
    {
      id: 34,
      title: "3rd Quarter Report (2080/81)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2080-81).pdf",
      year: "2080/81",
    },
    {
      id: 33,
      title: "2nd Quarter Report (2080/81)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2080-81).pdf",
      year: "2080/81",
    },
    {
      id: 32,
      title: "1st Quarter Report (2080/81)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2080-81).pdf",
      year: "2080/81",
    },
    {
      id: 31,
      title: "4th Quarter Report (2079/80)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2079-2080).pdf",
      year: "2079/80",
    },
    {
      id: 30,
      title: "3rd Quarter Report (2079/80)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2079-2080).pdf",
      year: "2079/80",
    },
    {
      id: 29,
      title: "2nd Quarter Report (2079/80)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2079-80).pdf",
      year: "2079/80",
    },
    {
      id: 26,
      title: "1st Quarter Report (2079/80)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/Unaudited Financial Statement First Quarter.pdf",
      year: "2079/80",
    },
    {
      id: 25,
      title: "4th Quarter Report (2078/79)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2078-79).pdf",
      year: "2078/79",
    },
    {
      id: 1,
      title: "3rd Quarter Report (2078/79)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/Unaudited Financial Statement Third Quarter.pdf",
      year: "2078/79",
    },
    {
      id: 2,
      title: "2nd Quarter Report (2078/79)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2078-79).pdf",
      year: "2078/79",
    },
    {
      id: 3,
      title: "1st Quarter Report (2078/79)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2078-79).pdf",
      year: "2078/79",
    },
    {
      id: 4,
      title: "4th Quarter Report (2077/78)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2077-78).pdf",
      year: "2077/78",
    },
    {
      id: 5,
      title: "3rd Quarter Report (2077/78)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2077-78).pdf",
      year: "2077/78",
    },
    {
      id: 6,
      title: "2nd Quarter Report (2077/78)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2077-78).pdf",
      year: "2077/78",
    },
    {
      id: 7,
      title: "1st Quarter Report (2077/78)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2077-78).pdf",
      year: "2077/78",
    },
    {
      id: 8,
      title: "4th Quarter Report (2076/77)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2076-77).pdf",
      year: "2076/77",
    },
    {
      id: 9,
      title: "3rd Quarter Report (2076/77)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2076-77).pdf",
      year: "2076/77",
    },
    {
      id: 10,
      title: "2nd Quarter Report (2076/77)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2076-77).pdf",
      year: "2076/77",
    },
    {
      id: 11,
      title: "1st Quarter Report (2076/77)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2076-77).pdf",
      year: "2076/77",
    },
    {
      id: 12,
      title: "4th Quarter Report (2075/76)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2075-76).pdf",
      year: "2075/76",
    },
    {
      id: 13,
      title: "3rd Quarter Report (2075/76)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2075-76).pdf",
      year: "2075/76",
    },
    {
      id: 14,
      title: "2nd Quarter Report (2075/76)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2075-76).pdf",
      year: "2075/76",
    },
    {
      id: 15,
      title: "1st Quarter Report (2075/76)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2075-76).pdf",
      year: "2075/76",
    },
    {
      id: 16,
      title: "4th Quarter Report (2074/75)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2074-75).pdf",
      year: "2074/75",
    },
    {
      id: 17,
      title: "3rd Quarter Report (2074/75)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2074-75).pdf",
      year: "2074/75",
    },
    {
      id: 18,
      title: "2nd Quarter Report (2074/75)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2074-75).pdf",
      year: "2074/75",
    },
    {
      id: 19,
      title: "1st Quarter Report (2074/75)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2074-75).pdf",
      year: "2074/75",
    },
    {
      id: 20,
      title: "4th Quarter Report (2073/74)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/4th Quarter Report (2073-74).pdf",
      year: "2073/74",
    },
    {
      id: 21,
      title: "3rd Quarter Report (2073/74)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/3rd Quarter Report (2073-74).pdf",
      year: "2073/74",
    },
    {
      id: 22,
      title: "2nd Quarter Report (2073/74)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/2nd Quarter Report (2073-74).pdf",
      year: "2073/74",
    },
    {
      id: 23,
      title: "1st Quarter Report (2073/74)",
      link: "https://gurkhasfinance.com.np/assets/quarterly/1st Quarter Report (2073-74).pdf",
      year: "2073/74",
    },
  ];

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                id="year-dropdown"
                onChange={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2081/82">2081/82</option>
                <option value="2080/81">2080/81</option>
                <option value="2079/80">2079/80</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    )
                )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default QuarterlyReport;
