import React, { useEffect } from "react";
import CardDownload from "../../Components/CardDownload";
import CardReport from "../../Components/CardReport";
import SecondaryBanner from "../../Components/SecondaryBanner";

export const MajorFinancialHighlight = () => {
  useEffect(() => {
    document.title = "Major Financial Highlights";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "Major Financial Highlights"],
    bannerTitle: "Major Financial Highlights",
  };
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      {/* <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select>
                <option>All Fiscal Year</option>
              </select>
            </div>Financial Highlights
          </div>
        </div>
      </section> */}
      <section class="section-report">
        <div class="container">
          <div class="card-row">
            <CardDownload
              cardTitle={"Financial Highlights"}
              link={"https://gurkhasfinance.com.np/assets/financial highlight/Financial Highlights.pdf"}
            />
          </div>
        </div>
      </section>
    </main>
  );
};
