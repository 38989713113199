import React from "react";

const NonFundedCredit = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>

        <div className="text-list">
          <div className="paragraph">
            <p style={{ marginBottom: "10px" }}>
              <strong>Bank Guarantee</strong>
            </p>
            <p className="small-light-text">
              Guarantee is an irrevocable promise made in writing by one person
              (guarantor or surety) to another party (the beneficiary) to answer
              for the debt or default of a third party. The commission will be
              charged for the full period of validity plus the period during
              which claims may be made at the time of the issue of the
              guarantee. We issue the below-mentioned guarantee as per the needs
              of the customers to execute their contracts and commitments.
              <ul>
                <li>Bid Bond Guarantee.</li>
                <li>Performance Guarantee.</li>
                <li>Advance Payment Guarantee.</li>
                <li>Supplier Credit Guarantee.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="paragraph paragraph-small">
          <p className="small-light-text">
            Gurkhas Finance Limited was established on 2051 B.S. It has been
            giving excellent service to its valuable customer for 26 years.
            Since the day of inception till date the motto of the finance has
            remained towards the better and easy service to the customer. The
            company has started its service from the paid up capital amount Rs.
            2 Crore. Now the company has been able to raise the paid up capital
            amounted to NRS 86.79 crore. The Profit, Net worth, collection of
            deposit and investment are in growing trend. The company is also
            able to abide by the rules and regulation of the regulatory bodies.
            It has been able to keep enough liquidity ratios. It is also succeed
            in providing the regular dividends to the shareholder.
          </p>
        </div>
      </div> */}
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default NonFundedCredit;
