import React from "react";

const ProfessionalLoan = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            A professional loan, as the name suggests, is a loan that can be
            taken out by professionals like accountants, doctors, journalists,
            engineers, dentists, company secretaries, surveyors, lawyers,
            architects, management consultants, and professionals involved in
            consultancy. It is non-collateral based loan.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Benefits</p>
          </div>
          <ul>
            <li>Convenient Repayment Plan.</li>
            <li>Competitive interest rates.</li>
            <li>Quick Processing Time.</li>
            <li>Easy documentation.</li>
            <li>Personalized service.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>Maximum Loan up-to NPR 1.5 Million.</li>
            <li>Flexible loan Tenure (Maximum up to 5 years )</li>
            <li>Hassle Free.</li>
            <li>Quick Processing Time.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Copy of Citizenship.</li>
            <li> Copy of Office ID.</li>
            <li> Copy of PAN card</li>
            <li> Recent PP size photograph.</li>
            <li> Salary Certificate from the Employer.</li>
            <li> Personal Bank Statements (6 months).</li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default ProfessionalLoan;
