import React from "react";

const LaghuKarja = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Propose</p>
          </div>
          <ul>
            <li>
              To fulfill the financing requirement of a customer against
              mortgages of real-estate collateral.
            </li>
            <li>Purchase of land and buildings and construction of house.</li>
            <li>Renovation and extension of existing house.</li>
            <li>
              Equity financing to meet a financial requirement of a client, etc.
            </li>
            <li>
              To fulfill a financing requirement to purchase land & building or
              construction of building as qualified under deprived sector
              lending for low-cost housing as per NRB directives.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>
              Up-to 60% of property value (Depend upon location and nature of
              loan).
            </li>
            <li>Maximum Tenure: up to 10 years</li>
            <li>Nature of loan: Term/OD.</li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Passport size photo of applicants / guarantor.</li>
            <li>Copy of citizenship document of borrowers / guarantor.</li>
            <li>Nature of loan: Term/OD.</li>
            <li>Copy of Land ownership document (lal purja).</li>
            <li>
              Copy of Transfer deed (Rajinama) if cooling period is not
              complete.
            </li>
            <li>Blue Print of Land.</li>
            <li>
              Building construction approval from Municipality / VDC along with
              drawing plans (applicable where building is involved).
            </li>
            <li>
              However, drawing of building is not mandatory for Loan size up to
              0.4 million.
            </li>
            <li>Char Killa (four boundary).</li>
            <li>Land revenue Tax Receipt (malpot receipt).</li>
            <li>
              Income certifying document (Salary letter, Audited balance sheet,
              rental agreement etc).
            </li>
            <li>
              Minimum Age of 18 years completed. Maximum age 70 years at the
              time of maturity.
            </li>
            <li>
              Building completion certificate from Municipality/VDC in case of
              readymade house purchase and loan against property.
            </li>
            <li>
              Income may be self-declared by borrower and/or certified by
              concerned authority.
            </li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default LaghuKarja;
