import React, { useEffect } from "react";
import { Table } from "../../Components/Table";

const CallDeposit = () => {
  const tableHeader = ["S.N.", "Call Deposit Scheme"];
  const tableData = [
    ["1", "Corporate Call Account"],
    ["2", "Interbank Call Account"],
  ];
  return (
    <div
      className="section-table fade-in"
      style={{ backgroundColor: "white", padding: "20px 0" }}
    >
      <div className="container">
        <Table tableHeader={tableHeader} tableData={tableData} />
      </div>
    </div>
  );
};

export default CallDeposit;
