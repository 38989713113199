import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../images/footer-logo.png";
import appstore from "../../images/download-appstore.png";
import googlestore from "../../images/download-google.png";
export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer-row">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <ul className="footer-list">
                <li>
                  <Link to="/notice">Notice</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
                <li>
                  <Link to="/subsidized-loan-disclosure">
                    Subsidized Loan Disclosure
                  </Link>
                </li>
                <li>
                  <Link to="/refinance-loan-disclosure">
                    Refinance Loan Disclosure
                  </Link>
                </li>
                <li>
                  <a
                    href="https://gunaso.nrb.org.np/complain"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NRB Grievance
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6">
              <ul className="footer-list">
                <li>
                  <Link to="/staff-training-details">
                    Staff Training Disclosure
                  </Link>
                </li>
                <li>
                  <Link to="/interest-rates">Interest Rate</Link>
                </li>
                <li>
                  <Link to="/tariffs">Tarrifs & Charges</Link>
                </li>
                <li>
                  <Link to="/basel-disclosure">Basel Disclosure</Link>
                </li>
                <li>
                  <Link to="/downloads">Downloads </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6">
              <ul className="footer-list">
                <li>
                  <Link to="/transactional-banking/qr-scan-pay">
                    QR Scan and Pay
                  </Link>
                </li>
                <li>
                  <Link to="/transactional-banking/debit-card">Debit Card</Link>
                </li>
                <li>
                  <Link to="/transactional-banking/remit-services">
                    Remit Service
                  </Link>
                </li>
                <li>
                  <Link to="/transactional-banking/locker-services">
                    Locker Service
                  </Link>
                </li>
                <li>
                  <Link to="/transactional-banking/demat">DEMAT</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6">
              <ul className="footer-list">
                <li>
                  <a
                    href="https://gurkhasfinance.com.np/assets/Dormat%20Account%20List%20Disclosure.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dormant Account List
                  </a>
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
                <li>
                  <Link to="/right-to-information">Right to Information</Link>
                </li>
                <li>
                  <Link to="/ci/information-officers">Information Officer</Link>
                </li>
                <li>
                  <Link to="/CapitalShareHolder">Capital Structure</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <Link to="/">
                <div className="footer-logo">
                  <img src={footerLogo} alt="" />
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-logo-container">
                <div className="address-contact">
                  <p>Tel: 014537401, 014529060</p>
                  <p>info@gurkhasfinance.com.np</p>
                  <p>Charkhal, Dillibazar, Kathmandu</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="head" style={{ width: "285px" }}>
                <p>Download Gurkhas Mobile Banking App</p>
              </div>
              <div className="image-container">
                <div className="image">
                  <a
                    href="https://apps.apple.com/us/app/gurkhas-smart/id1269458364"
                    target="blank"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <div className="image">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.f1soft.gurkhasmobilefinance&hl=en"
                    target="blank"
                  >
                    <img src={googlestore} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 disclaimer">
              <p>Disclaimer | Privacy Policy | Terms & Condition</p>
              <p>&copy; Copyright Gurkhas Finance Ltd.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
