import React from 'react'
import banner from "../images/banner.png"
export default function SecondaryBanner(props) {
    const {bannerData}=props;
    // console.log(bannerData);
  return (
    <section className="section-banner">
    <div className="breadcrumb">
        {bannerData.breadcrumb && bannerData.breadcrumb.map((item,index)=>{
           return( <li key={index}>{item}</li>)         
    })}
    </div>

    <div className="header-text">
        <h1>{bannerData.bannerTitle}</h1>
    </div>
    <div className="image-container">
        <img src={banner} alt=""/>
    </div>
</section>
  )
}

