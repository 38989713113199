import React from "react";
import { Link } from "react-router-dom";
export default function SpecialSaving() {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">
            <strong>Introduction</strong>
          </p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            People seeking higher interest rate can open this account.
          </p>
        </div>
        <div className="sub-text-container">
          <ul>
            {/* <li><strong>Interest Rate</strong>: <span><Link to="/interest-rates">As per Published Rate </Link></span></li>
                                        <li><strong>Interest Posting Frequency</strong>: Quarterend </li>
                                        <li><strong>Deposit/Withdraw Limit</strong>: Unlimited</li>
                                        <li><strong>Eligibility</strong>: Individual</li> */}
            <li>
              <strong>Interest Rate</strong>:{" "}
              <span>
                <Link to="/interest-rates">As per Published rate</Link>
              </span>
            </li>
            <li>
              <strong>Debit Card Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Internet Banking Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Mobile Banking Charge</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>ABBS</strong>: Free
            </li>
            <li>
              <strong>Locker</strong>:{" "}
              <span>
                <Link to="/tariffs">As per STC</Link>
              </span>
            </li>
            <li>
              <strong>Cheque Book</strong>: Free
            </li>
            <li>
              <strong>Interest Calculation</strong>: Interest Calculation on a
              daily basis and payable quarterly
            </li>
            <li>
              <strong>Minimum Balance</strong>: NRS. 5,000.00{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">
              <strong>Features:</strong>
            </p>
          </div>
          <ul>
            <li>Free Cheque book issuance.</li>
            <li>No restrictions for withdrawals and deposits</li>
            <li>Internet Banking, Mobile banking and Debit Card facility</li>
            <li>Free Account Statement on request</li>
            <li>Any Branch Banking Services (ABBS)</li>
            <li>Accidental Insurance</li>
          </ul>
        </div>
      </div>

      {/* <div className="text-container">
                                <div className="header-box">
                                    <p className="title">Required Documents</p>
                                </div>
                                <div className="paragraph paragraph-small">
                                    <p className="small-light-text">Gurkhas Finance Limited was established on 2051 B.S. It
                                        has been giving excellent service to its valuable customer for
                                        26 years. Since the day of inception till date the motto of the finance has
                                        remained towards the better and easy service
                                        to the customer. The company has started its service from the paid up capital
                                        amount Rs. 2 Crore. Now the company has
                                        been able to raise the paid up capital amounted to NRS 86.79 crore. The Profit,
                                        Net worth, collection of deposit and
                                        investment are in growing trend. The company is also able to abide by the rules
                                        and regulation of the regulatory bodies.
                                        It has been able to keep enough liquidity ratios. It is also succeed in
                                        providing the regular dividends to the
                                        shareholder.</p>
                                </div>
                            </div>
                            <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
}
