import React, { useEffect, useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
import CardReport from "../../Components/CardReport";
import SecondaryBanner from "../../Components/SecondaryBanner";

export const Media = () => {
  useEffect(() => {
    document.title = "Media";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Media"],
    bannerTitle: "Media",
  };
  const initialState = {
    selectedYear: "all",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const cardData = [
    {
      id: 1,
      title: "Press Release - Credit Software",
      link: "https://gurkhasfinance.com.np/assets/press release/Press Release - Credit Software.pdf",
      year: "2078/79",
    }, {
      id: 2,
      title: "Press Release - Deposit",
      link: "https://gurkhasfinance.com.np/assets/press release/Press Release - Deposit.pdf",
      year: "2078/79",
    }, {
      id: 3,
      title: "Press Release - Nari Bachat",
      link: "https://gurkhasfinance.com.np/assets/press release/Press Release - Nari Bachat.pdf",
      year: "2078/79",
    },
    {
      id: 4,
      title: "Press Release AGM",
      link: "https://gurkhasfinance.com.np/assets/press release/Press Release AGM.pdf",
      year: "2078/79",
    },
  ]
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      {/* <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select>
                <option>All Fiscal Year</option>
              </select>
            </div>Financial Highlights
          </div>
        </div>
      </section> */}
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                <CardDownload
                  cardTitle={card.title}
                  link={card.link}
                  key={card.id}
                />
              ))
              : cardData.map(
                (card) =>
                  card.year === state.selectedYear && (
                    <CardDownload
                      cardTitle={card.title}
                      link={card.link}
                      key={card.id}
                    />
                  )
              )}
          </div>
        </div>
      </section>
    </main>
  );
};
