import React from "react";

const SmeLoan = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            SME Loan is the funding of small and medium-sized enterprises and
            help for development of enterpreneurship or generating employment in
            the society.
          </p>
        </div>
      </div>
      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Features</p>
          </div>
          <ul>
            <li>
              Facilities Type: Overdraft Loan/ Term Loan/ Demand Loan and Short
              Term Loan
            </li>
            <li>
              Purpose: To Finance Working capital, capital expenditure
              requirements including refinancing of equity
            </li>
            <li>LTV: Up to 75% of FMV(Depending on nature of business).</li>
            <li>Term: 15 years.</li>
            <li>Overdraft: 1 year revolving basis.</li>
            <li>Demand Loan: 1 year payable on maturity.</li>
            <li>Competitive interest rate.</li>
            <li>Flexible Loan Tenure.</li>
            <li>Both funds based and non- fund based credit facilities.</li>
            <li>Easy Processing.</li>
          </ul>
        </div>
      </div>

      <div className="text-container">
        <div className="text-list">
          <div className="header-box">
            <p className="title">Required Documents</p>
          </div>
          <ul>
            <li>Loan Application</li>
            <li>Audited and 3 years projected Financial Report.</li>
            <li>
              Copy of Firm/ PAN/ VAT Registration Certificate, Partnership Deed,
              Latest Tax Clearance Certificate.
            </li>
            <li>MOA/ AOA (If Any).</li>
            <li>Company’s Profile</li>
            <li>
              Board Resolution for opening account, Availing Loan, Delegation of
              Authority (If any).
            </li>
            <li>
              Copy of citizenship and 1 copy of PP size photograph of all
              directors.
            </li>
            <li>Project Proposal (If any).</li>
            <li>Profile (Biodata) of the Directors/ Shareholders.</li>
            <li>
              Documents related to fixed assets security (i.e. Lal Purja, Tiro
              Rashid, etc).
            </li>
            <li>Bank Statement.</li>
            <li>
              Detail report of Stocks, Account Receivables, Account Payables,
              Goods in Transit, Sales, Purchase etc.
            </li>
            <li>
              If Directors/ Shareholders are involved in other company details
              of the company.
            </li>
            <li>List of major Suppliers/ Buyers.</li>
            <li>Main products of the unit/ Firm/ Industry/ Company</li>
            <strong style={{ marginTop: "20px", display: "inline-block" }}>
              Note:
            </strong>
            <li>
              If the mode of transfer is “Rajinama” the cooling off period must
              elapse 1 Years 35 days
            </li>
            <li>For “Bakaspatra”- 2 years and 35 days.</li>
            <li>For "Ankshabanda”- 3 months.</li>
            <li>For “Mrityu Namsari”- 3 years.</li>
          </ul>
        </div>
      </div>
      {/* <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default SmeLoan;
