import React from "react";
import secondaryBannerImage from "../../images/locker.png";

const LockerServices = () => {
  return (
    <div className="savings-information fade-in">
      <div className="text-container">
        <div className="header-box">
          <p className="title">Introduction</p>
        </div>
        <div className="paragraph">
          <p className="small-light-text">
            Gurkhas has been providing Safe Deposit Locker facility to its
            valued customers in order to safeguard valuable belongings. Our
            Locker services are well spread throughout the nation and we have
            various sizes to cater this need.{" "}
          </p>
        </div>
      </div>
      <div className="image-container">
        <img src={secondaryBannerImage} alt="" />
      </div>
      {/* <div className="text-container">
        <div className="header-box">
          <p className="title">Required Documents</p>
        </div>
        <div className="text-list">
          <div className="paragraph paragraph-small">
            <p className="small-light-text">
              <strong>Primary applicant:</strong>
            </p>
            <li>
              Duly filled Safe Deposit Locker Service Request Form with Account
              information.
            </li>
            <li>Recent Photographs</li>
            <li>Citizenship</li>
            <p className="small-light-text">
              <strong>Secondary applicant (if required):</strong>
            </p>
            <li>Updated Know Your Customer (KYC) form</li>
            <li>Recent Photographs</li>
            <li>Citizenship</li>
          </div>
        </div>
      </div>
      <button className="btn btn-secondary">Apply Now</button> */}
    </div>
  );
};

export default LockerServices;
