import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "./Table";
import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";
import IntroTable from "./Table";
export default function CapitalShareHolderBankingHour() {
  const { id } = useParams();
  const [bannerData, setBannerData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === "shareholder-structure") {
      document.title = "Share Holder Structure";
      setBannerData({
        breadcrumb: ["Home", "About Us", "Share Holder Structure"],
        bannerTitle: "Share Holder Structure",
      });
    } else {
      document.title = "Banking Hours";
      setBannerData({
        breadcrumb: ["Home", "About Us", "Banking Hours"],
        bannerTitle: "Banking Hours",
      });
    }
  }, [id]);
  return (
    <>
      {bannerData && <SecondaryBanner bannerData={bannerData} />}
      <IntroTable />
    </>
  );
}
