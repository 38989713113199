import React from "react";
import { useEffect } from "react";
import CardDownload from "../../Components/CardDownload";
import SecondaryBanner from "../../Components/SecondaryBanner";

const Download = () => {
  useEffect(() => {
    document.title = "Downloads";
    window.scrollTo(0, 0);
  }, []);

  const bannerData = {
    breadcrumb: ["Home", "Downloads"],
    bannerTitle: "Downloads",
  };

  const cardData = [
    {
      id: 5,
      title: "KYC Details For Corporate Account",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Kyc_corporate_account.pdf",
    },
    {
      id: 6,
      title: "Ammendments On Gurkha Bank Smart",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Ammendment bank smart.pdf",
    },

    {
      id: 7,
      title: "CDD/ECDD Review Checklist For Personal Account",
      link: "https://gurkhasfinance.com.np/assets/Downloads/CDD and ECDD-- 1 page.pdf",
    },
    {
      id: 8,
      title: "Debit Card From",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Debit Card From.pdf",
    },

    {
      id: 9,
      title: "Signature Speciment Card",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Signature Speciment Card new.pdf",
    },
    {
      id: 10,
      title: "Individual KYC Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Individual KYC Form.pdf",
    },

    {
      id: 11,
      title: "Mobile Banking Application Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Mobile Banking.pdf",
    },
    {
      id: 12,
      title: "Personal Account Opening Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Personal Account Opening Form.pdf",
    },
    {
      id: 13,
      title: "Internet Banking Application Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Internet Banking.pdf",
    },
    {
      id: 14,
      title: "CASBA Application Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/C-Asba_Application_Form.pdf",
    },
    {
      id: 15,
      title: "CASBA Registration Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/C-Asba_Registration_Form.pdf",
    },
    {
      id: 16,
      title: "Gold Loan Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Gold Loan.pdf",
    },
    {
      id: 17,
      title: "Beneficial Owner Account Opening Form For Individuals",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Demat Account .pdf",
    },
  ];

  const formCardData = [
    {
      id: 1,
      title: "Standing Instruction Slip",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Standing Instruction Slip.pdf",
    },

    {
      id: 7,
      title: "Demat Family Transfer",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Demat-Family-Transfer.pdf",
    },
    {
      id: 7,
      title: "De Materialization Request Forms",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Dematerialization request forms.pdf",
    },
    {
      id: 7,
      title: "Demat Death Transfer",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Demat-Death-Transfer.pdf",
    },
    {
      id: 7,
      title: "Debit Instruction Slip",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Debit Instruction Slip.pdf",
    },
    {
      id: 7,
      title: "Bo Bo Transfer Form (Individuals)",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Bo Bo Transfer Form (Individuals).pdf",
    },
    {
      id: 7,
      title: "Bank Account Update Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Bank Account Update Form.pdf",
    },
    {
      id: 1,
      title: "Institutional Account Opening Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/institutional-account-opening-form.pdf",
    },
    {
      id: 3,
      title: "Fixed Deposit Account Opening Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Fixed Deposit.pdf",
    },
    {
      id: 3,
      title: "Signature Change Application form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Signature Change Application Form.pdf",
    },
    {
      id: 4,
      title: "Physical Death Transfer Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Physical Death Transfer Form.pdf",
    },
    {
      id: 5,
      title: "Minor to major Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Minor to Major Forms.pdf",
    },
    {
      id: 6,
      title: "Meroshare Link Application Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/MeroShare Link Application Form.pdf",
    },
    {
      id: 7,
      title: "Demat Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Demat form.pdf",
    },
    {
      id: 7,
      title: "Meroshare Form",
      link: "https://gurkhasfinance.com.np/assets/Downloads/Meroshare 8000 pcs.pdf",
    },
  ];

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-report">
        <div class="container">
          <div class="card-row">
            {cardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
          <div className="box-divider">
            <h2 style={{ padding: "0px 40px", lineHeight: "1.8" }}>
              Demat Section
            </h2>
          </div>
          <div class="card-row">
            {formCardData.map((card) => (
              <CardDownload
                cardTitle={card.title}
                link={card.link}
                key={card.id}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Download;
